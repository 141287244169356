import type React from 'react';
import {
  createContext,
  useState,
  useCallback,
  useContext,
  useEffect,
} from 'react';

export interface Toast {
  id: number;
  status: 'success' | 'error';
  message: string;
  duration: number;
}

interface ToastContextType {
  toasts: Toast[];
  addSuccessToast: (message: string) => void;
  addErrorToast: (message: string) => void;
  removeToast: (id: number) => void;
  getToastMessages: () => string[];
}

const NO_AUTO_DELETE_DURATION = 0;

const ToastContext = createContext<ToastContextType | undefined>(undefined);

export const PtnToastProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<Toast[]>([]);

  const addSuccessToast = useCallback((message: string) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      { id: Date.now(), status: 'success', duration: 3000, message },
    ]);
  }, []);

  const addErrorToast = useCallback((message: string) => {
    setToasts((prevToasts) => [
      ...prevToasts,
      {
        id: Date.now(),
        status: 'error',
        duration: NO_AUTO_DELETE_DURATION,
        message,
      },
    ]);
  }, []);

  const removeToast = useCallback((id: number) => {
    setToasts((prevToasts) => prevToasts.filter((toast) => toast.id !== id));
  }, []);

  const getToastMessages = useCallback(() => {
    return toasts.map((toast: Toast) => toast.message);
  }, [toasts]);

  return (
    <ToastContext.Provider
      value={{
        toasts,
        addSuccessToast,
        addErrorToast,
        removeToast,
        getToastMessages,
      }}
    >
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = (): ToastContextType => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error(
      'useToast は ToastProvider の中で利用される必要があります。',
    );
  }
  return context;
};

const arrayEqual = (arrayA: unknown[], arrayB: unknown[]) => {
  if (arrayA.length !== arrayB.length) return false;
  return arrayA.every((value, index) => value === arrayB[index]);
};

// ErrorHandling の処理だが、Toastに依存する挙動をまとめておきたいため1ファイルに置く
export const useErrorHandler = (errorMessages?: string[]): void => {
  const { getToastMessages, addErrorToast } = useToast();

  useEffect(() => {
    if (errorMessages && errorMessages.length > NO_AUTO_DELETE_DURATION) {
      if (arrayEqual(getToastMessages(), errorMessages)) {
        return;
      }
      errorMessages
        .filter((errorMessage: string) => {
          return !!errorMessage; // 空文字対応
        })
        .forEach((errorMessage: string) => {
          addErrorToast(errorMessage);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorMessages, addErrorToast]);
};
